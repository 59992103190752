<template>
  <div>
    <SpinnerLoader v-if="broadcastReferenceStatus !== 'success'" :loading="broadcastReferenceStatus" />
    <template v-else-if="broadcastReferenceStatus === 'success' && broadcastReference">
      <virtual-scroll :items="broadcastReference.items" :item-height="28.6" class="vscroll table-responsive mb-0 broadcast-reference">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th class="w-30px">№</th>
                <th>
                  <div class="resizable brand">{{ $t('table.brand') }}</div>
                </th>
                <th>
                  <div class="resizable commercial">{{ $t('table.commercial') }}</div>
                </th>
                <th class="text-right w-100px">{{ $t('reports.duration') }}</th>
                <th class="text-right w-100px">{{ $t('reports.exits') }}</th>
                <th class="text-right w-100px">{{ $t('reports.seconds') }}</th>
                <th v-if="with_wgrp" class="text-right w-100px">wGRP</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.order_number">
                <td>{{ item.order_number }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="commercial">
                  {{ item.commercial_name }}
                  <CoBrandBadge v-if="item.co_branding_discount != null" />
                </td>
                <td class="text-right">{{ item.commercial_duration }}</td>
                <td class="text-right">{{ item.spot_count }}</td>
                <td class="text-right">{{ item.seconds_total }}</td>
                <td v-if="with_wgrp" class="text-right">{{ item.plan_fact_wgrp | toFixedAndSpace }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <td colspan="4">{{ $t('booking.total') }}</td>
                <td class="text-right">{{ broadcastReference.totals.spot_count }}</td>
                <td class="text-right">{{ broadcastReference.totals.seconds_total }}</td>
                <td v-if="with_wgrp" class="text-right">{{ broadcastReference.totals.plan_fact_wgrp | toFixedAndSpace }}</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'BroadcastReference',
  components: {
    SpinnerLoader,
    VirtualScroll,
    CoBrandBadge: () => import('@/components/CoBrandBadge.vue'),
  },
  filters: { toFixedAndSpace },
  props: {
    with_wgrp: { type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      broadcastReference: 'getReportBroadcastReference',
      broadcastReferenceStatus: 'getReportBroadcastReferenceStatus',
    }),
  },
  destroyed() {
    this.$store.commit('clearReportBroadcastReference');
  },
  methods: {},
};
</script>
<style lang="sass">
.w-30px
  width: 30px
.w-100px
  width: 100px

.reports-page div.table-responsive.broadcast-reference
  height: calc(100vh - 210px)
  width: 1100px !important
  margin-left: auto
  margin-right: auto

.reports-page .broadcast-reference table.table
  div.resizable
    &.brand,
    &.commercial
      min-width: 200px

  td[name="brand"],
  td[name="commercial"]
    max-width: 200px

@media (min-width: 1671px)
  .reports-page div.table-responsive.broadcast-reference
    height: calc(100vh - 154px)
</style>
